import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'T3QkyavttrOI1TNfhD6BGxFagVBcsTab4iAB',
  sdkSecret: 'XSMWHPsTJ9olgUQN0LKEiQnNtjwL6Yfi0eYn',
  topic: 'test-remoto-react',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
};
